<template>
  <div>
    <a-card title="客户计费配置">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="客户" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="items"
            :pagination="pagination"
            :loading="loading"
            :scroll="{ y: 480 }"
            @change="onChangeTable"
          >
            <template slot="action" slot-scope="value, item, index">
              <a-button-group size="small">
                <a-button type="primary" @click="handleEdit(item)">编辑</a-button>
              </a-button-group>
            </template>
          </a-table>
        </a-col>
        <add-modal ref="modal_add" @ok="initData"></add-modal>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { chargConfigsList } from "@/api/charging";

export default {
  name: "ChargeItemsPanel",
  data() {
    return {
      loading: false,
      searchForm: { page: 1, page_size: 48 },
      pagination: { current: 1, total: 0, pageSize: 48, showTotal: (total) => `共 ${total} 条` },
      items: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "客户",
          dataIndex: "client_name",
          key: "client_name",
        },
        {
          title: "入库配送单价(元)",
          dataIndex: "stock_in_transport_unit_price",
          key: "stock_in_transport_unit_price",
        },
        {
          title: "入库卸货单价(元)",
          dataIndex: "stock_in_handling_unit_price",
          key: "stock_in_handling_unit_price",
        },
        {
          title: "仓储单价(元)",
          dataIndex: "warehouse_storage_unit_price",
          key: "warehouse_storage_unit_price",
        },
        {
          title: "出库装车单价(元)",
          dataIndex: "stock_out_handling_unit_price",
          key: "stock_out_handling_unit_price",
        },
        {
          title: "出库配送单价(元)",
          dataIndex: "stock_out_transport_unit_price",
          key: "stock_out_transport_unit_price",
        },
        // {
        //   title: '入库配送计量单位',
        //   dataIndex: 'stock_in_transport_measure_unit',
        //   key: 'stock_in_transport_measure_unit',
        // },
        // {
        //   title: '入库卸货计量单位',
        //   dataIndex: 'stock_in_handling_measure_unit',
        //   key: 'stock_in_handling_measure_unit',
        // },
        // {
        //   title: '仓储计量单位',
        //   dataIndex: 'warehouse_storage_measure_unit',
        //   key: 'warehouse_storage_measure_unit',
        // },
        // {
        //   title: '出库装车计量单位',
        //   dataIndex: 'stock_out_handling_measure_unit',
        //   key: 'stock_out_handling_measure_unit',
        // },
        // {
        //   title: '出库配送计量单位',
        //   dataIndex: 'stock_out_transport_measure_unit',
        //   key: 'stock_out_transport_measure_unit',
        // },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  components: {
    AddModal: () => import("./modules/addModal"),
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.list();
    },
    handleEdit(item) {
      this.$refs.modal_add.show(item);
    },
    handelAdd() {
      this.$refs.modal_add.show();
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    list() {
      this.loading = true;
      chargConfigsList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.selectedRowKeys = [];
          this.loading = false;
        });
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style></style>
